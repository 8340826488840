(function($) {
  $.fn.simpleToggleClass = function(toggleClassName, toggleClassElements, outfocus, behavior) {
    var _this = $(this);
    var className = toggleClassName;
    var elements = toggleClassElements || false;
    var outfocus = outfocus || false;
    var behavior = behavior || false;
    
    _this.on('click', function(e) {
      if (behavior === false)
      e.preventDefault();
      
      if (_this.hasClass(className)) {
        _this.removeClass(className);
        
        if (elements !== false)
        $(elements).removeClass(className);
      } else {
        _this.addClass(className);
        
        if (elements !== false)
        $(elements).addClass(className);
      }
    });
    
    if (outfocus === true) {
      $(document).on('click touchend', function(e) {
        var target = e.target;
        if ($(target).closest(_this).length === 0) {
          _this.removeClass(className);
          
          if (elements !== false)
          $(elements).removeClass(className);
        }
      });
    }
  }
  
  $.fn.setHeight = function(height) {
    return this.each(function(index, el) {
      $(el).outerHeight(height);
    });
  }
  
  $.fn.getBackground = function(options) {
    return this.each(function() {
      var bgSrc = $(this).data('background');
      $(this).css({
        backgroundImage: 'url(' + bgSrc + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      });
    });
  }
  
  $.fn.keepRatio = function(ratio, breakpoint) {
    var rt = ratio || null;
    var bp = breakpoint || 0;
    
    if (ratio === null)
    return;
    
    var elm = this;
    var _elm = $(elm);
    var getWidth = _elm.outerWidth();
    
    function destroyHeight(obj) {
      $(obj).height('');
    }
    
    function getNewHeight(obj) {
      var _obj = $(obj);
      var getWidth = _obj.outerWidth();
      var newHeight = Math.round(getWidth / rt);
      return newHeight;
    }
    
    if (window.innerWidth > bp) {
      _elm.outerHeight(getNewHeight(elm));
    }
    
    $(window).on('resize', $.throttle(1000 / 60, function() {
      var getNewWidth = _elm.outerWidth();
      if (window.innerWidth > bp) {
        _elm.outerHeight(getNewHeight(elm));
      } else {
        destroyHeight(elm);
      }
    }));
  }
  
  $.fn.elementFilling = function(options) {
    var $win = $(window);
    
    return this.each(function(index, el) {
      var $elm = $(el);
      var configs = $.extend({
        position: $elm.data('position') || 'center',
        parentSelecter: $elm.parent(),
        resizeTrigger: {
          enabled: false,
          disabledOn: 0,
          disabledOnReverse: false,
        },
      }, options);
      var $par = $(configs.parentSelecter);
      
      function getRatio(elm) {
        var ratio = elm.outerWidth() / elm.outerHeight();
        return ratio;
      }
      
      function compareRatio(_parent, _child) {
        var parentRatio = getRatio(_parent);
        var childRatio = getRatio(_child);
        if (parentRatio > childRatio) {
          return 'fit-width';
        } else {
          return 'fit-height';
        }
      }
      
      function updatePosition() {
        var ratio = compareRatio($par, $elm);
        var position = configs.position;
        switch (ratio) {
          case 'fit-width':
          $elm.css({
            width: '100%',
            height: 'auto',
            maxHeight: 'none',
            marginLeft: 0,
          });
          
          switch (position) {
            case 'top':
            $elm.css('marginTop', 0);
            break;
            case 'bottom':
            $elm.css('marginTop', ($par.outerHeight() - $elm.height()));
            break;
            default:
            $elm.css('marginTop', ($par.outerHeight() - $elm.height()) / 2);
            break;
          }
          break;
          case 'fit-height':
          $elm.css({
            width: 'auto',
            height: '100%',
            maxWidth: 'none',
            marginTop: 0,
          });
          
          switch (position) {
            case 'left':
            $elm.css('marginLeft', 0);
            break;
            case 'right':
            $elm.css('marginLeft', ($par.outerWidth() - $elm.width()));
            break;
            default:
            $elm.css('marginLeft', ($par.outerWidth() - $elm.width()) / 2);
            break;
          }
          break;
        }
      }
      
      function destroyPosition() {
        $elm.removeAttr('style');
      }
      
      if (options !== false) {
        $(configs.parentSelecter).imagesLoaded(function(ev) {
          updatePosition();
        });
        
        var rt = configs.resizeTrigger;
        if (rt.enabled === true) {
          $(window).on('resize', function() {
            switch (rt.disabledOnReverse) {
              case true:
              if ($win.width() <= rt.disabledOn) {
                updatePosition();
              } else {
                destroyPosition();
              }
              break;
              default:
              if ($win.width() > rt.disabledOn) {
                destroyPosition()
              } else {
                updatePosition();
              }
              break;
            }
          });
        }
      } else {
        destroyPosition();
      }
    });
  }
  
  $.fn.exapandableBox = function(options) {
    var defaults = $.extend({
      content: '',
      toggleButton: '',
      slideSpeed: '',
      disabledOn: 0,
    }, options);
    
    return this.each(function() {
      var $parent = $(this);
      var $content = $(defaults.content);
      var $toggle = $parent.find(defaults.toggleButton);
      var href = $toggle.attr('href');
      
      function showContent(animationSpeed) {
        $content.addClass('opened');
        $(href).slideDown(animationSpeed);
      }
      
      function hideContent(animationSpeed) {
        $content.removeClass('opened');
        $(href).slideUp(animationSpeed);
        setTimeout(function() {
          $parent.height('');
        }, animationSpeed);
      }
      
      $toggle.on('touchStart click', function(ev) {
        ev.preventDefault();
        var tempHi = $parent.outerHeight(true);
        $parent.height(tempHi);
        
        if ($content.hasClass('opened')) {
          hideContent(defaults.slideSpeed);
        } else {
          showContent(defaults.slideSpeed);
        }
      });
      
      $(window).on('resize', $.throttle(1000 / 60, function() {
        if (window.innerWidth <= defaults.disabledOn) {
          hideContent(0);
        }
      }));
      
      $(document).on('touchStart click', function(ev) {
        if (window.innerWidth > defaults.disabledOn && $(ev.target).closest('.content-expandable').length === 0) {
          hideContent(defaults.slideSpeed);
        }
      });
    });
  };
  
  $.headerScrollFX = function(breakpoint) {
    var $sh = $('.site-header');
    var $sf = $('.site-footer');
    var shHi = $sh.outerHeight();
    var sfPos = $sf.offset().top;
    var bp = breakpoint || 0;
    
    function scrollHeader() {
      $sh.removeClass('fixed');
      $sh.css({
        top: Math.floor(sfPos - shHi - 1) + 'px',
      });
    }
    
    function fixedHeader() {
      $sh.removeAttr('style');
      $sh.addClass('fixed');
    }
    
    function destroy() {
      $sh.removeAttr('style');
      $sh.removeClass('fixed');
    }
    
    if (window.innerWidth > bp) {
      if (sfPos <= window.scrollY + shHi) {
        scrollHeader();
      } else {
        fixedHeader();
      }
    } else {
      destroy();
    }
    
    $(window).on('scroll resize load', $.throttle(1000 / 60, function() {
      var shHi = $sh.outerHeight();
      var sfPos = $sf.offset().top;
      
      function scrollHeader() {
        $sh.removeClass('fixed');
        $sh.css({
          top: Math.floor(sfPos - shHi) + 'px',
        });
      }
      
      function fixedHeader() {
        $sh.removeAttr('style');
        $sh.addClass('fixed');
      }
      
      function destroy() {
        $sh.removeAttr('style');
        $sh.removeClass('fixed');
      }
      
      if (window.innerWidth > bp) {
        if (sfPos <= window.scrollY + shHi) {
          scrollHeader();
        } else {
          fixedHeader();
        }
      } else {
        destroy();
      }
    }));
  }
  
  $.setMinHeight = function() {
    var sh = $('.site-header').outerHeight();
    var tb = $('.topbar').outerHeight();
    $('.site-main').css('minHeight', sh - tb);
  }
  
  $.fn.elementQueryClass = function(breakpoint, fillClass, operator) {
    var el = $(this);
    var bp = breakpoint || 0;
    var op = operator || 'less than';
    
    function runScript() {
      var wi = el.outerWidth();
      
      switch (op) {
        case 'less than':
        if (wi < bp) {
          el.addClass(fillClass);
        } else {
          el.removeClass(fillClass);
        }
        break;
        case 'equal less than':
        if (wi <= bp) {
          el.addClass(fillClass);
        } else {
          el.removeClass(fillClass);
        }
        break;
        case 'greater than':
        if (wi > bp) {
          el.addClass(fillClass);
        } else {
          el.removeClass(fillClass);
        }
        break;
        case 'equal greater than':
        if (wi >= bp) {
          el.addClass(fillClass);
        } else {
          el.removeClass(fillClass);
        }
        
      }
    }
    
    return this.each(function(idx, el) {
      runScript();
      
      $(window).on('resize', $.throttle(1000 / 60, function() {
        runScript();
      }));
    });
  }
  
  $.scrollToTop = function() {
    var arrow = $('<button class="go-to-top-button"><i class="icon fa fa-angle-up"></i></button>');
    var speed = 0;
    $('body').append(arrow);
    
    arrow.on('touchstart mouseenter', function() {
      $(this).addClass('hover');
    }).on('touchend mouseleave', function() {
      $(this).removeClass('hover');
    });
    
    arrow.on('click touchend', function() {
      $('html, body').animate({
        scrollTop: 0,
      }, speed);
    });
    
    window.onscroll = function() {
      var top = window.scrollY;
      speed = top / 2;
      
      if (top > 400) {
        arrow.addClass('visible')
      } else {
        arrow.removeClass('visible');
      }
    };
  };
  
  /* =Hero Carousel */
  var moreThanOne = ($('#hero_carousel').find('img').length > 1) ? true : false;
  $('#hero_carousel').owlCarousel({
    items: 1,
    loop: moreThanOne,
    pullDrag: moreThanOne,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
  });
  
  /* Gallery Carousel */
  var $gs = $('.gallery-carousel');
  var moreThanOne = ($gs.find('img').length > 1) ? true : false;
  $gs.owlCarousel({
    items: 1,
    pullDrag: moreThanOne,
    loop: moreThanOne,
    nav: true,
    dots: true,
    smartSpeed: 800,
    navText: ['<i class="icon fa fa-angle-left"></i>', '<i class="icon fa fa-angle-right"></i>'],
  });
  
  /* =Booking Form */
  function getSecretCodeFromDataAttr(formSelector) {
    var form = document.getElementById(formSelector);
    var sCode = form.dataset.secretCode;
    var code = (sCode) ? sCode : '';
    return code;
  }
  
  $('#booking_form').booking({
    propertyId: '#property',
    arrivalSelector: '#arrival',
    departureSelector: '#departure',
    adultSelector: '#adult',
    childSelector: '#child',
    roomSelector: '#room',
    codeSelector: '#code',
    submitSelector: '#submit',
    secretCode: getSecretCodeFromDataAttr('booking_form'),
    onlineId: 5,
    language: 'en',
    languageCalendar: {
      monthShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      monthFull: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    enabled: true,
    debug: false,
  });
  
  $('.content-expandable-wrap').exapandableBox({
    content: '.content-expandable',
    toggleButton: '.expand',
    slideSpeed: 200,
    disabledOn: 600,
  });
  
  /* =Anchor Link */
  $('[data-anchor]').each(function() {
    var el = $(this);
    var tar = el.data('anchor');
    var page = $('html, body');
    el.on('click touchStart', function(event) {
      var winPos = Math.round($(window).scrollTop());
      var tarPos = Math.round($(tar).offset().top);
      var smartSpeed = Math.abs(winPos - tarPos);
      if ($(tar).length) {
        history.pushState(null, null, tar);
        event.preventDefault();
        page.stop().animate({
          scrollTop: tarPos,
        }, smartSpeed * 1.2);
        return false;
      }
    });
  });
  
  /* =Anchor Link on Page loaded */
  var hash = window.location.hash;
  if (hash && $(hash).length) {
    scrollTo(0, 0);
    var tar = hash;
    var page = $('html, body');
    var winPos = Math.round($(window).scrollTop());
    var tarPos = Math.round($(tar).offset().top);
    var smartSpeed = Math.abs(winPos - tarPos);
    page.animate({
      scrollTop: tarPos,
    }, smartSpeed * 1.2);
  }
  
  /* =Fixed Ratio */
  $('[data-ratio]').each(function() {
    var ratio = $(this).data('ratio');
    $(this).keepRatio(ratio);
  });
  
  /* =Background */
  $('[data-background]').getBackground();
  
  /* =Booking Responsive */
  $('#booking_panel').elementQueryClass(400, 'narrow');
  
  /* Magnific Inline Type */
  $('.open-mfp-popup').magnificPopup({
    type: 'inline',
    closeBtnInside: false,
    mainClass: 'mfp-fly-in',
    removalDelay: 300,
    callbacks: {
      open: function() {
        $('.mfp-fly-in').addClass('animated');
      },
      beforeClose: function() {
        $('.mfp-fly-in').removeClass('animated');
      },
    },
  });
  
  $.validate({
    form: '#contact_form',
    modules: 'html5',
    onSuccess: function($form) {
      var form = $form[0];
      var fromURL = form.baseURI;
      var formAction = form.action;
      var formData = $form.serialize() + '&url=' + fromURL;
      
      var result = $form.find('.result');
      var gresult = $form.find('.g-recaptcha-result');
      var sendto = $form.find('input[name="sendto"]').val();
      var txtSuccess = '<i class=\"fa fa-check-circle-o\"></i> Thank you for getting in touch! <br/>We try to respond as soon as possible.';
      var txtFailed = '<i class=\"fa fa-check-times-o\"></i> Sorry, There are somethings error. Please contact directly to <a href="mailto:' + sendto + '">' + sendto + '</a>';
      var txtError = '<i class=\"fa fa-check-times-o\"></i> Sorry, mail() function has error. Please contact directly to <a href="mailto:' + sendto + '">' + sendto + '</a>';
      
      function recaptchaResponse(display, text) {
        if (display === 'show') {
          gresult.html(text).slideDown();
        }
        if (display === 'hide') {
          gresult.empty().hide();
        }
      }
      
      console.log(formData);
      $.post(formAction, formData, function(data, status, jqXHR) {
        switch (data) {
          case 'mail-success':
          $form
          .removeClass('failed')
          .addClass('success')
          .find('*:focus').blur();
          
          result.hide();
          result.html(txtSuccess).slideDown();
          recaptchaResponse('hide');
          
          form.reset();
          grecaptcha.reset();
          break;
          case 'recaptcha-failed':
          recaptchaResponse('show', 'Please check the recaptcha');
        }
      });
      
      return false;
    },
  });
  
  $.validate({
    form: '#newsletter, #newsletter-home',
    modules: 'html5',
    onSuccess: function($form) {
      var form = $form[0];
      var fromURL = form.baseURI;
      var formAction = form.action;
      var formData = $form.serialize() + '&url=' + fromURL;
      
      var result = $form.find('.result');
      var txtSuccess = 'Subscription Successful';
      var txtFailed = 'Subscription Failed! ...';
      
      $.post(formAction, formData, function(data, status, jqXHR) {
        switch (status) {
          case 'success':
          $form
          .addClass('success')
          .removeClass('failed')
          .find(':focus').blur();
          result.hide();
          result.html(txtSuccess).slideDown();
          form.reset();
          break;
          case 'error':
          $form
          .addClass('failed')
          .removeClass('success');
          result.hide();
          result.html(txtFailed).slideDown();
        };
      });
      
      return false;
    },
    onError: function($form) {
      var form = $form[0];
      var result = $form.find('.result');
      result.hide();
    },
  });
  
  /* =Mobile Menu */
  function controlPageScroll(value) {
    $('html').css('overflow', value);
  }
  
  function openMobileMenu() {
    $('html').addClass('menu-visible');
    controlPageScroll('hidden');
  }
  
  function closeMobileMenu() {
    $('html').removeClass('menu-visible');
    controlPageScroll('auto');
  }
  
  $('.m-menu-toggle').on('click touchend', function() {
    openMobileMenu();
  });
  
  $('.m-menu').on('click touchend', '.has-sub-menu > a', function(ev) {
    var wrapmenu = $(this).parent('li.has-sub-menu');
    var submenu = $(this).next('ul.sub-menu');
    if (submenu.is(':hidden')) {
      ev.preventDefault();
      $(this).next('ul.sub-menu').slideDown();
    }
    wrapmenu.addClass('menu-toggled').siblings('li.has-sub-menu').removeClass('menu-toggled').children('ul.sub-menu').slideUp();
  });
  
  var currentMenu = $('.m-menu').find('li.current');
  currentMenu.parents('li.has-sub-menu').addClass('menu-toggled');
  currentMenu.parents('ul.sub-menu').show();
  
  $('.m-menu-filter').on('click', function() {
    closeMobileMenu();
  });
  
  $(window).on('resize', function() {
    if (window.innerWidth > 992) {
      closeMobileMenu();
    }
  });
  
  /* =Mobile Relate Hotel */
  var lb = $('.list-branches');
  var mlb = $('<div class="m-list-branches" />');
  mlb.append('<div class="dd-selected" />');
  mlb.append('<ul class="dd-options" />');
  lb.each(function(idx, el) {
    var menuClone = lb.find('li').clone();
    mlb.find('.dd-options').append(menuClone);
  });
  var txt = mlb.find('.dd-options li.current a').html();
  mlb.find('.dd-selected').html(txt);
  lb.after(mlb);
  
  mlb.on('click', function() {
    $(this).toggleClass('visible');
  });
  
  $(document).on('click touchstart', function(ev) {
    if ($(ev.target).closest(mlb).length === 0) {
      mlb.removeClass('visible');
    }
  });
  
  /* =Menu Select */
  $('.menu-select').simpleToggleClass('visible', false, true, false);
  
  /* =Mobile Book Button */
  $('#m_booknow').simpleToggleClass('toggled', false, true, true);
  
  $(window).on('load scroll resize', function() {
    var winY = window.scrollY;
    var winHei = window.innerHeight;
    var el = $('#booking_bar');
    var sticky = el.offset().top;
    var stickyBtm = sticky + el.outerHeight();
    if (sticky <= winY) {
      el.addClass('sticky');
    } else {
      el.removeClass('sticky');
    }
    
    if (stickyBtm >= winY + winHei) {
      el.addClass('sticky-bottom');
    } else {
      el.removeClass('sticky-bottom');
    }
  });
  
  $(window).on('load', function() {
    setTimeout(function() {
      $('html').removeClass('preload');
    }, 100);
    $.setMinHeight();
  });
  
  $(window).on('resize', $.throttle(1000 / 60, function() {
    $('html').addClass('resizing');
    $.setMinHeight();
  }));
  
  $(window).on('resizeend', $.throttle(1000 / 60, function() {
    $('html').removeClass('resizing');
  }));
  
  // Lazy Load Google Map
  var executed = false,
  gmap = $('#google_maps'),
  gmapPos;
  
  function updateGoogleMapPosition() {
    var posY = Math.round(gmap.offset().top);
    return posY;
  }
  
  function initGoogleMap() {
    var center = [13.733025, 100.550071];
    var aetasBangkok = [13.738092, 100.549213],
    aetasLumpini = [13.725052, 100.547268],
    aetasResidence = [13.738090, 100.549594];
    gmap.gmap3({
      center: center,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
    }).marker([{
      position: aetasBangkok,
    }, {
      position: aetasLumpini,
    }, {
      position: aetasResidence,
    }]);
    executed = true;
  }
  
  $(window).on('load resizeend', function() {
    gmapPos = updateGoogleMapPosition();
  });
  
  $(window).on('load scroll', function() {
    var winPosY = window.scrollY,
    winHeight = window.innerHeight,
    winBottom = winPosY + winHeight;
    if(winBottom >= gmapPos) {
      if( !executed )
      initGoogleMap();
    }
  });
})(jQuery);